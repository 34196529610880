.Settings{
    width: 80%;
    margin: auto;
}
.Settings img {
    display: block;
    width: 400px;
    margin: auto ;
    margin-top: 20px;
}
.Settings p{
    width: fit-content;
    display: block;
    margin: 10px auto 50px auto;
    font-size: 22px;
    font-weight: bold
}
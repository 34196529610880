    .Button{
    height: 50px;
    width: 240px;
    margin: auto;
    margin-top: 10px;
    /* background: #01ACC1; */
    color: white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 8px;
    border: none;
    padding: 12px 18px;
    box-shadow: 0 0 4px #999;
    outline: none;
    cursor: pointer;
    transition-duration: 0.2s;
    text-decoration: none;
    overflow: hidden;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }
  
  .Button.ripple {
    background-position: center;
    transition: background 0.8s;
    -webkit-transition: background 0.8s;
    -moz-transition: background 0.8s;
    -ms-transition: background 0.8s;
    -o-transition: background 0.8s;
  }
  .Button.ripple:hover {
    background: rgb(5, 191, 216) radial-gradient(circle, transparent 1%, rgb(5, 191, 216) 1%) center/15000%;
  }
  .Button.ripple:active {
    background-color: rgb(111, 223, 240);
    background-size: 100%;
    transition: background 0s;
    -webkit-transition: background 0s;
    -moz-transition: background 0s;
    -ms-transition: background 0s;
    -o-transition: background 0s;
  }
  .Button.H1{
    font-size: 20px
  }
  
  .Loading{
    color:white
  }
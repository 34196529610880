.Cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.RadioButtonList {
  display: flex;
  justify-content: space-evenly;
  margin: 30px auto;
  flex-wrap: wrap;
}
.statusOptions h2 {
  text-align: center;
}
.emptyState {
  margin-top: 50px;
  text-align: center;
}
.emptyState img {
  height: 100px;
}
.InvoicesCards {
  text-align: center;
}
.InvoicesCard {
  padding: 0px;
  display: flex;
  /* justify-content: center; */
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.number {
  margin: 10px;
  text-align: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #01acc1;
  color: white;
  border-radius: 25px;
  width: 40px;
  height: 40px;
  font-size: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.Button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.Excell {
  padding: 10px;
  margin-bottom: 20px !important;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-content: center;
  align-items: flex-start;
  align-self: flex-start;
  justify-self: left;
  width: 250px;
  /* margin: auto; */
  background-color: #008000;
  color: white;
  border: 1px solid #4854682d;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
}
.Excell p {
  margin-left: 22px;
  text-align: center;
}
.Grid {
  margin: auto;
  /* width: 110%; */
  margin-top: 50px;
  /* margin-bottom: 50px; */
  height: 800px;
  /* align-items: center */
}

.Reset {
  flex: 1;
  /* display: flex; */
  align-content: center;
  align-self: center;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
  margin-top: -20px;
}

.SearchInput {
  display: flex;
  width: fit-content;
  margin: 20px auto !important;
}
@media (max-width: 670px) {
  .SearchInput {
    width: 85%;
  }
}

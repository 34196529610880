.Validator {
  text-align: center;
  width: 90%;
  margin: auto;
}
.Validator h2 {
  margin-bottom: 20px;
}
.QR img {
  height: 150px;
  width: auto;
}
.SearchInput {
  display: flex;
  width: fit-content;
  margin: 30px auto !important;
}

.Original{
  font-size: 20px;
  color: rgb(104, 169, 7);
  font-weight: bold
}
.Fake{
  font-size: 20px;
  color: rgb(155, 19, 19);
  font-weight: bold
}
.Form{
  width: 80%;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  background: #ffffff;
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  padding: 20px
}
.TextField{
  background: white;
  padding: 20px
}
.Form button{
  height: 50px;
  width: 240px;
  margin: auto;
  margin-top: 10px;
  background: #01ACC1;
  color: white;
  font-size: 20px;
  font-weight: bold;
  border-radius: 8px;
  border: none;
  padding: 12px 18px;
  box-shadow: 0 0 4px #999;
  outline: none;
  cursor: pointer;
  transition-duration: 0.2s;
  text-decoration: none;
  overflow: hidden;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}

.ripple {
  background-position: center;
  transition: background 0.8s;
  -webkit-transition: background 0.8s;
  -moz-transition: background 0.8s;
  -ms-transition: background 0.8s;
  -o-transition: background 0.8s;
}
.ripple:hover {
  background: rgb(5, 191, 216) radial-gradient(circle, transparent 1%, rgb(5, 191, 216) 1%) center/15000%;
}
.ripple:active {
  background-color: rgb(111, 223, 240);
  background-size: 100%;
  transition: background 0s;
  -webkit-transition: background 0s;
  -moz-transition: background 0s;
  -ms-transition: background 0s;
  -o-transition: background 0s;
}
.H1{
  font-size: 20px
}



@media (max-width: 670px) {
  .SearchInput {
    width: 85%;
  }
}

.InvoicesCards {
    text-align: center;
  }
  .InvoicesCard {
    flex:5;
    padding: 0px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
  }

  .number {
    margin: 10px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    background-color: #01acc1;
    color: white;
    border-radius: 25px;
    width: 40px;
    height: 40px;
    font-size: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }
  
  .InvoiceNumber{
    width: 150px;
  }
  .SuggestionBox{
    width :120%
  }

  .Container{
    display: flex;
  }

  .CardLabel{
    flex:1;
    display: flex;
    justify-content:center;
    align-content: center;
    align-items: center;
  }
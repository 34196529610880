.Card {
    padding: 10px;
    margin-top: 20px !important;
    width: 95%;
    margin: auto;
    background-color: rgb(236, 235, 235);
    border: 1px solid #4854682d;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
  }

.LabelsGenerator{
  width: 100%;
  margin: auto;
  margin-top: 10px;
}
.Grid {
  margin: auto;
  /* width: 110%; */
  margin-top: 50px;
  /* margin-bottom: 50px; */
  height: 1000px;
  /* align-items: center */
}

.NumberOfInvoices{
  display: flex;
  justify-content: center;
}

.Invalid {
  width: 80%;
  margin: auto;
  color: red;
}

.TextField {
  width: 80%;
  margin: auto !important;
  margin-top: 10px;
}
.MultiInput {
  width: 80%;
  margin: auto !important;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.SubTextField {
  width: 45%;
}

.Invoice {
  width: 45%;
  display: flex;
  flex-direction: column;
}
.Invoice TextField {
  width: 100%;
}
.SizedBox {
  height: 5px;
}
.LoadingSpinner{
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 40;
}
.LoadingSpinner LoadingSpinner{
height: 400px;
}

.Buttons{
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.CellItem{
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
}
.CellItem:focus{
  outline: none;
}

@media (max-width: 1035px) {
  .SizedBox {
    height: 10px;
  }
}

@media (max-width: 763px) {
  .SizedBox {
    height: 15px;
  }
  .Invoice{
    font-size: 8px;
  }
}

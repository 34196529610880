.Login {
  width: 60%;
  margin: 30px auto;
}
.Login h1 {
  color: #485468 !important;
}
.Form {
  background-color: white;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border: 1px solid #4854682d;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
}
.Form img {
  display: block;
  margin: -10px auto;
  width: 250px;
}
.Paper {
  padding: 2px 4px;
  display: flex;
  flex-direction: column;
  align-items: center !important;
  width: 90%;
  height: 55px;
  margin: auto;
  padding: 10px;
  margin-top: 25px;
  background-color: #f6f6f6 !important;
}

.SubTextField {
  margin-top: -4px !important;
  width: 100%;
  /* background-color: rgb(211, 216, 216) */
}
.Button {
  width: fit-content;
  margin: auto;
}
.Button button {
  border: 0;
}
.Invalid {
    display: block;
    width: 80%;
    margin: auto;
    color: red;
    margin-top: 5px
}

@media (max-width: 1000px) {
    .Login {
        width: 80%;
    }      
  }
  @media (max-width: 800px) {
    .Login {
        width: 90%;
    }      
  }

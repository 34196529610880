.RadioContainer {
    height: 25px;
    width: fit-content;
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .RadioContainer p {
    margin-top: 10px;
  }
  
  .RadioContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .Checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: rgba(255, 255, 255, 0.598);
    border-style: solid;
    border-color: #979797;
    border-width: 2px;
    border-radius: 50%;
  }
  .Node{
    position: absolute;
    opacity: 0;
    top: 15%;
    left: 20%;
    height: 10px;
    width: 10px;
    background-color: #01ACC1;
    border-width: 2px;
    border-radius: 50%;
  }
  
  .RadioContainer:hover input ~ .Checkmark {
    border-color: #01ACC1;
  }
  
  /* When the checkbox is checked, add a blue background */
  .RadioContainer input:checked ~ .Checkmark {
    border-color: #01ACC1;
  }
  .RadioContainer input:checked ~ .Checkmark .Node {
    opacity: 1;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .Checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .RadioContainer input:checked ~ .Checkmark:after {
    display: block;
  }

  @media (max-width: 763px) {
    
    .RadioContainer{
      font-size: 12px;
    }
  }
  

.OfflineModal {
    /* display: none; Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 3; /* Sit on top */
    left: 0px;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.6); /* Black w/ opacity */
  }

  .ModalContent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #fefefe;
    width: 50% !important;
    margin: 15% auto; 
    padding: 20px;
    border: 1px solid #888;
  }
  .Title{
      color: rgb(177, 5, 5);
      font-weight: bold;
      font-size: 20px
  }
  .Paragraph{
      margin-top: -10px
  }
  
  @media (max-width: 900px) {
    .ModalContent {
        margin: 25% auto;;
    }
  }
  
.Footer {
  position: absolute;
  left: 240px;
  /* margin-top: 10px; */
  bottom: 0;
  /* height: 50px; */
  width: calc(100% - 240px);
  color: rgb(219, 148, 148);
  display: flex;
  justify-content: space-between;
  background-color: rgba(37, 45, 59, 0.927);
  /* flex-wrap: wrap; */
}
.Left {
  color: rgb(69, 69, 69);
}
.Left ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.Left a {
  color: rgb(255, 255, 255);
  text-decoration: none;
  margin-right: 30px;
}

.Left a:hover,
.Left a:active,
.Left a.active {
  color: rgb(1, 172, 193);
}

.Right {
  margin-right: 10px;
  color: rgb(255, 255, 255);
}
.Right ul {
  display: flex;
  flex-wrap: wrap;
}
.Right a {
  color: rgb(69, 69, 69);
  text-decoration: none;
}

@media (max-width: 601px) {
  .Footer {
    /* display: none; */
    left: 0;
    width: 100%;
  }
}

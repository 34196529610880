.TitledItem {
  margin: 10px;
  display: flex;
  align-items: center;
}
.Title {
  color: #01acc1;
  font-weight: bolder;
  font-size: 16px;
}

.DatePicker {
  display: flex;
  padding: 10px
}

.DatePicker input {
  margin-left: 10px;
  color: rgb(0, 0, 0);
  border: 1px solid #01acc1;
  height: 40px;
  font-size: 18px;
  border: 1px solid #97979765;
  box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

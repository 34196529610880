.ListItem {
  color: white;
}

.ListItemIcon {
  color: white;
}

.ListItem a:active,
.ListItem a.active {
  background-color: rgb(1, 172, 193);
  color: white;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.FormBox {
  width: 80%;
  margin: auto;
  margin-top: 50px;
  background: #ffffff;
  box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  /* align-items: center */
}
.FormBox h1 {
  display: flex;
  justify-content: center;
}
.Invalid {
    width: 80%;
    margin: auto;
    color: red
}
  
.TextField {
  width: 80%;
  margin: auto !important;
  margin-top: 10px;
}
.MultiInput {
  width: 80%;
  margin: auto !important;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}
.SubTextField{
    width:45%;
}
.Button{
    display: flex;
    justify-content: center !important;
    justify-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
    flex-direction: column;
  }
.Button button{
    border: 0  #ffffff00;
}
.Spinner{
    display: flex;  
    justify-content: center

  }
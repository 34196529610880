.AddStock {
  text-align: center;
  width: 90%;
  margin: auto;
}
.AddStock h1{
  margin-bottom: 30px !important;
  color: #485468 !important;
}

.Cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Table {
  margin-bottom: 20px;
}

.RTswitch{
    margin-top: 20px;
    margin-bottom: -25px;
    display: flex;
    align-items: center;
}
.RTswitch p{
    font-weight: bold
}
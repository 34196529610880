.Customer{
    width:75%;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: 1px 3px 4px 0 rgba(0, 0, 0, 0.5);
}
.H1{
    color: #485468 !important;
}
.Row{
    display: flex;
    justify-content: space-between
}
.Cards{
    width: 75%;
    margin: auto;
    margin-top: 40px;
    display: flex;
    justify-content: space-between;

}
h1{
    width:75%;
    margin: auto;
    text-align: center;
    margin-top: 20px
}

@media (max-width: 1200px){
    .Customer{
        width: 85%;
    }
    h1{
        width: 85%;
    }
    .Cards{
        width: 85%
    }
}
@media (max-width: 950px){
    .Row{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 70%;
        margin: auto
    } 
}
@media (max-width: 800px){
    .Row{
        width: 90%;
    } 
}
@media (max-width: 670px){
    .Row{
        width: 100%;
    } 
    .Customer{
        width: 90%
    }
}


.Customers {
  width: 90%;
  margin: auto;
  display: flex;
  flex-direction: column;
}
.Customers h1{
    color: #485468 !important;
}
.CreateButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px
}
.Customtoolbar{
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px

    /* margin-left: -20% */
}
.CustomToolbarPaper{
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 400px;
}
.Input{
    width:80%
}
.Table{
    cursor: pointer;    

}

@media (max-width: 750px){
    .CustomToolbarPaper{
        width: 290px
    }
    .Customtoolbar{
        margin-left: 0px
    }
}

@media (max-width: 600px){
    .CustomToolbarPaper{
        width: 400px
    }
    .Customtoolbar{
        justify-content: center;
        /* margin-left: 5% */
    }
}
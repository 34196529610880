.AddStock {
    text-align: center;
    width: 90%;
    margin: auto;
  }
  .AddStock h1{
    color: #485468 !important;
      margin-bottom: 20px
  }
  
  .Cards {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .Table {
    margin-bottom: 20px;
  }
  
  .RTswitch{
      margin-top: 20px;
      margin-bottom: -25px;
      display: flex;
      align-items: center;
  }
  .RTswitch p{
      font-weight: bold
  }
  .Paper{
    padding: 2px 4px;
    display: flex;
    align-items: center !important;
    width: 300px;
    height: 60px;
    margin: auto;
    padding: 10px
    /* background-color: rgb(255, 255, 255);
    box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
    padding: 10px */

  
}

.SubTextField{
  width: 100%
}
.Button button{
  border: 0;
}

.ContactElement {
  display: flex;
  align-items: center;
  padding: 10px;
}
.Icon{
    margin-right: 5px;
    color: rgba(57, 57, 57, 0.884)
}
.Title{
    margin-right: 10px;
    color: rgba(57, 57, 57, 0.884)
}
.Dashboard {
  width: 90%;
  margin: auto;
  align-items: center;
}
.Dashboard h1 {
  color: #485468 !important;
}

.Chart {
  padding: 10px;
  margin-top: 20px !important;
  width: 98%;
  margin: auto;
  background-color: rgb(255, 255, 255);
  border: 1px solid #4854682d;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  box-shadow: 1px 2px 5px 1px rgba(156, 155, 155, 0.5);
}
.Chart p {
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: HelveticaNeue-Bold;
  font-size: 16px;
  color: #485468;
  letter-spacing: 0;
}

.StatCards {
  display: flex;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;
  /* padding: 50px !important */
}
.Spinner {
  transform: translate(50%, -295%);
  -webkit-transform: translate(50%, -295%);
  -moz-transform: translate(50%, -295%);
  -ms-transform: translate(50%, -295%);
  -o-transform: translate(50%, -295%);
}

.Tabs {
  display: flex;
  justify-content: space-around;
}
.Tab {
  width: 100%;
  height: 40px;
  margin-right: -1px;
  margin-left: -1px;
  background: #252e3b;
  font-size: 14px;
  font-weight: bold;
  color: white;
  outline: none;
}
.Tab:hover {
  background-color: #336e7b;
}
.TabActive {
  background: #01ACC1;
}

.Table table {
  border-collapse: collapse;
  width: 100%;
  margin-top: 20px
}

.Table th, .Table td {
  text-align: left;
  padding: 8px;
}

.Table tr:nth-child(even) {background-color: #f2f2f2;}

.Title{
  font-size: 22px !important
}
.Download{
  display: flex;
  justify-content: center;
  margin-top: 10px;
}